// eslint-disable-next-line import/named
import { Wrap, Text, Highlighted, CheckMark } from './styled';

function SuccessPaymentPage() {
  return (
    <Wrap>
      <Text>
        Third Party Payment <Highlighted>Successful</Highlighted>
      </Text>
      <CheckMark>✔</CheckMark>
    </Wrap>
  );
}

export default SuccessPaymentPage;
