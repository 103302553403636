import styled from '@emotion/styled';

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh; /* Full viewport height for perfect centering */
  text-align: center;
`;

const Text = styled.div`
  font-size: 30px;
  font-weight: bold;
`;

const Highlighted = styled.span`
  color: #28a745; /* Green color for "Successful" */
`;

const CheckMark = styled.div`
  font-size: 40px;
  color: #28a745; /* Green color matching "Successful" */
  margin-top: 10px; /* Space between text and checkmark */
`;

export { Wrap, Text, Highlighted, CheckMark };
