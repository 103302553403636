// eslint-disable-next-line import/named
import { Wrap, Text, Highlighted, CrossSymbol } from './styled';

function FailedPaymentPage() {
  return (
    <Wrap>
      <Text>
        Third Party Payment <Highlighted>Failed</Highlighted>
      </Text>
      <CrossSymbol>✖</CrossSymbol>
    </Wrap>
  );
}

export default FailedPaymentPage;
